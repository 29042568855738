import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './styles/Footer.css'

export const Footer = () => {
  return (
    <Container fluid className='p-4 footerBG'>
        <Row>
            <Col className='text-center'>
                Teen Leaf
            </Col>
        </Row>
    </Container>
  )
}
