import React, {Suspense} from "react";
import "./App.css";
import { Container } from "react-bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./Layout";
import { Gigs } from "./components/Gigs";
import { Footer } from "./components/Footer";

//lazy load components
const Home = React.lazy(() => import('./components/Home')) ;
const Music = React.lazy(() => import('./components/Music')) ;


function App() {
  return (
    <div className="App">
      <Container fluid={true} className="p-0 m-0">
        <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/music" element={<Music/>} />
              <Route path="/gigs" element={<Gigs/>} />
              {/* <Route path="/about" element={<h1>Music</h1>} />
              <Route path="/classes" element={<h1>Music</h1>} /> */}
            </Route>
          </Routes>
          </Suspense>
        </BrowserRouter>
        <Footer/>
      </Container>

    </div>
  );
}

export default App;
