import React from "react";
import { Container, Navbar, Nav, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

export const MainNav = () => {
  const renderNavLink = (path: string, label: string) => (
    <Nav.Item className="mx-2">
      <NavLink
        to={path}
        className={({ isActive }) => {
          if (isActive) {
            return "homeNavLink activeNavLink";
          } else {
            return "homeNavLink";
          }
        }}
      >
        {label}
      </NavLink>
    </Nav.Item>
  );

  return (
    <Navbar expand="sm" variant="light" className="custom-navbar">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            {renderNavLink("/", "Home")}
            {renderNavLink("music", "Music")}
            {renderNavLink("gigs", "Gigs")}
            {/* {renderNavLink("about", "About")} */}
            {/* {renderNavLink("classes", "Classes")} */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
