import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  region: 'ap-southeast-1',
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

export const getImagesList = async (bucketName: string): Promise<string[]> => {
  try {
    const result = await s3.listObjectsV2({ Bucket: bucketName }).promise();
    return result.Contents?.map(item => `https://${bucketName}.s3.amazonaws.com/${item.Key}`) || [];
  } catch (error) {
    console.error('Error fetching images:', error);
    return []; // Return an empty array on error
  }
};

export interface VideoIDs {
  videoIds: string[];
}

export const getVideoList = async (bucketName: string, fileName: string): Promise<VideoIDs> => {
  try {
    const data = await s3.getObject({
      Bucket: bucketName,
      Key: fileName
    }).promise();

    const content = data.Body?.toString('utf-8');
    const parsedContent: VideoIDs = JSON.parse(content || '{}');
    return parsedContent;
  } catch (error) {
    console.error('Error fetching video IDs:', error);
    return { videoIds: [] }; // Return an object with an empty videoIds array on error
  }
};
