import React from "react";
import { MainNav } from "./components/Nav";
import { Outlet } from 'react-router-dom';


export const Layout= () => {
  return (
    <>
      <header role="banner">
        <MainNav />
      </header>
      <main role="main"><Outlet/></main>
    </>
  );
};
