import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ImgWall from './specialComponents/ImgWall';
import { getImagesList } from './utils/getS3Fetch';

export const Gigs = () => {
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // New loading state

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imageList = await getImagesList('lakshangigs');
        setImages(imageList);
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        setLoading(false); // Set loading to false whether the fetch succeeds or fails
      }
    };

    fetchImages();
  }, []);

  return (
    <Container fluid className='p-4'>
      <Row>
        <Col>
          {loading ? (
            <div>Loading...</div> 
          ) : (
            
              <ImgWall imageLinks={images} />
            
          )}
        </Col>
      </Row>
    </Container>
  );
};
