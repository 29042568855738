import React from 'react';
import { Container, Row, Col, Card,CardGroup  } from 'react-bootstrap';


interface ImgWallProps {
    imageLinks:string[]
}

const ImgWall:React.FC<ImgWallProps> = ({ imageLinks }) => {
    return (
        <Container>
            <Row>
                {imageLinks.map((link, index) => (
                    <Col md={4} key={index}>
                        <Card className="mb-4">
                            <Card.Img variant="top" src={link} />
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default ImgWall;
